import { UserIcon } from '@heroicons/react/24/outline';
import { useLocation } from '@remix-run/react';
import { useState } from 'react';
import { DesktopNavigationBar } from '~/components/navigation/desktop-navbar';
import { HeaderNavigationBar } from '~/components/navigation/header-bar';
import { MobileNavigationBar } from '~/components/navigation/mobile-navbar';
import { Routes } from '~/constants/routes';

export default function PublicApp() {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const mobileNavigation = [
    {
      to: Routes.Login,
      name: 'Sign In',
      icon: UserIcon,
      labelClassName: 'text-white',
    },
  ];
  return (
    <>
      <MobileNavigationBar
        navigation={mobileNavigation}
        pathname={pathname}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <DesktopNavigationBar
        navigation={[]}
        pathname={pathname}
        className="lg:w-44"
      />
      <HeaderNavigationBar
        pathname={pathname}
        setSidebarOpen={setSidebarOpen}
        className="lg:pl-44"
      />
    </>
  );
}
